@import "../../style/varibale";

.main-container {
  display: flex;
  z-index: 9999;

  @media screen and (max-width: 900px) {
    display: none;
  }
  main {
    padding: 10px;
  }
}
main {
  background-color: white !important ;
}
/* Sidebar */
.sidebar {
  .top_section {
    display: flex;
    align-items: normal;
    justify-content: space-between;
    padding: 7px 18px;

    .logo {
      font-size: 18px;
      line-height: 0;
      width: 129px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: $mainColor;
      -webkit-user-select: none;
      user-select: none;
      margin: auto;
    }

    .bars {
      color: $secondColor !important;

      cursor: pointer;
    }
  }
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  .link {
    display: flex;
    color: #fff !important;
    gap: 10px;
    padding: 5px 18px;
    text-decoration: none;
    border-right: 4px solid transparent;
    transition: all 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    cursor: pointer;
    .logoSvg path {
      fill: $textColor !important;
    }
    &:hover {
      // border-left: 4px solid $secondColor;
      transition: 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
      color: $mainColor;
      position: relative;
      .logoSvg path {
        fill: $textColor !important;
        transition: fill 0.1s ease-in-out;
        // width:  15px !important;
      }

      &::after {
        content: "";
        height: 90%;
        width: 4px;
        position: absolute;
        left: 0%;
        top: 6%;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: none !important;
        color: #fff !important;
        .logoSvg path {
          fill: $mainColor !important;
          color: $mainColor !important;
        }
      }
    }

    .link_text {
      white-space: nowrap;
      text-decoration: none !important;
      text-decoration: underline;
      font-family: $fontBase;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 30px;
      color: $textColor;
    }

    // transition: all 1s !important;
    width: 0% !important;
  }

  .active {
    // transition: all 1s !important;

    background-color: none;
    width: 100% !important;
    color: #ffff !important;
    width: 20px;
    position: relative;
    &::after {
      content: "";
      height: 90%;
      width: 4px;
      position: absolute;
      left: 0%;
      top: 6%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: $mainColor;
      color: #ffff !important;
    }
    .logoSvg:hover path {
      fill: $mainColor !important;
    }
    .logoSvg path {
      fill: $mainColor !important;
      color: $mainColor !important;
    }
  }

  .menu {
    display: flex;
    color: $mainColor;
    // padding-right: 20px;
    transition: 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
  }

  .menu_item {
    display: flex;
    gap: 10px;
  }

  .menu_container {
    display: flex;
    flex-direction: column;

    .active {
      color: #fff !important;
      width: 20px;
      position: relative;

      &::after {
        content: "";
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        background-color: #fff;
      }
    }

    margin-top: -12px;

    .link {
      display: flex;
      color: #fff;
      gap: 10px;
      text-decoration: none;
      transition: 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);

      &:hover {
        transition: 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
        color: $mainColor;
        position: relative;

        &::after {
          content: "";
          background-color: transparent;
        }
      }
    }
  }

  .menu_container .link {
    padding-left: 20px;
  }
}

.routesAr {
  .link {
    &:hover {
      // border-left: 4px solid $mainColor;
      transition: 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
      color: red;
      position: relative;

      &::after {
        content: "";
        height: 90%;
        width: 4px;
        position: absolute;
        right: -4px !important;
        top: 6%;
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        background-color: $secondColor;
      }
    }
    // transition: all 1s !important;
    width: 0% !important;
  }

  .active {
    // transition: all 1s !important;

    // background-color: #faa9402f;
    width: 100% !important;
    color: #ffff !important;
    &::after {
      right: -4px !important;
      top: 6%;
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      color: #ffff !important;
      background-color: none;
    }
  }
  .menu_container {
    .active {
      &::after {
        content: "";
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
    .link {
      display: flex;
      color: $textColor;
      gap: 10px;
      text-decoration: none;
      transition: 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);

      &:hover {
        transition: 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
        color: $secondColor;
        position: relative;

        &::after {
          content: "";
          background-color: transparent;
          color: $textColor !important;
        }
      }
    }
  }
}

.side {
  height: 100%;
  width: inherit;
  position: fixed;
  top: 0;
  // left: 0;
  background-color: #fff;
  z-index: 99;
  overflow: scroll;
  overflow-x: hidden;
  // display: none;
}

.react-tooltip {
  z-index: 999999999999999 !important;
}
.main-container_nav {
  display: none;
}
@media screen and (max-width: 900px) {
  // .main-container {
  //     // display: none !important;
  // }
  .main-container_nav {
    display: block !important;
  }
  .adminLayout {
    width: 100% !important;
  }
}

.active .logoSvg path {
  display: block;

  transition: fill 0.2s ease-in-out;
  width: 40px !important;

}

.active .logoSvg:hover path {
  fill: $textColor !important;
}

.active .link_text {
  background-color: transparent;
  color: $mainColor !important;
}
