@import "../../../style/varibale";

.app__auth {
  background-color: #e6ebf3;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .app__login {
    width: 100%;

    .app__login-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100% !important;
      img {
        width: 200px;
        height: 100px;
      }

      .input_form {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 15px;

        label {
          align-self: flex-start;
          font-size: 0.913rem;
          font-weight: 500;
          margin: 0;
          padding-bottom: 4px;
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
            Helvetica Neue, sans-serif !important;
          -webkit-font-smoothing: antialiased !important;
          color: #000;
        }

        .error__handel {
          color: $colorRed-1;
        }

        input {
          background: #e6ebf3 !important;
          height: 50px !important;
          /* Shadow */
          border-radius: 10px !important;
          padding: 20px;
          outline: none;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
        }

        .password__input {
          position: relative;

          .password__show {
            position: absolute;
            right: 0;
            border-radius: 25px;
            height: 100%;
            background-color: transparent;
            color: #4a4a4a;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white !important;
          }
        }
      }

      .app__login-btn {
        height: 52px;
        margin: 1rem auto 0;
        width: 52px;
        border-radius: 50%;
        outline: none;
        border: none;
        background-color: $mainColor;
        display: flex;
        align-items: center;
        justify-content: center;

        // margin-top: 20px !important;
        .app__login-btn-icons path {
          stroke: #f9f9f9;
          color: #ffffff;
        }
      }
    }
  }

  .avatar {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // align-items: end;

    @media screen and (max-width: 991px) {
      display: none !important;
    }
  }
}

.login__form {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.opisity {
  opacity: 0.5;
}

.opisity1 {
  opacity: 1 !important;
}

.bg__login {
  background-color: white;
  padding: 30px 0;
  border-radius: 5px;
}
.bg__login-icons {
  padding: 15px;
}
.login__form_inputs {
  width: 75%;

  @media screen and (max-width: 500px) {
    width: 85% !important;
  }
}
.spinner-border {
  color: white !important;
  width: 25px !important;
  height: 25px !important;
}

.password_open {
  position: relative;
  .app__login-btn-icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
    width: 20px !important;
  }
}
.login {
  background-color: #ffffff;
}
.login-sidebar {
  background-color: #65539a !important;
}
.login-container {
  width: 888px;
  height: 703px;
  display: flex;
  flex-direction: column;
}
.login-input {
  width: 450px;
  height: 45px;
  border-radius: 10px;
  border: solid 1px #999999;
  background-color: #f5f5f5 !important;
}
.login-btn {
  background-color: $secondColor !important;
  color: #ffff;
  font-size: 18px;
  width: 450px;
  height: 45px;
  border-radius: 10px;
}

.login-btn:focus {
  outline: none !important;
  border: none;
  border-color: #999999 !important;
  box-shadow: none;
}
.login-btn:focus-visible {
  border-color: #999999 !important;
}
.left-sidebar {
  margin-left: 0;
  margin-right: auto;
}

/* For right sidebar (Arabic) */
.right-sidebar {
  margin-right: 0;
  margin-left: auto;
}
.halalk-login {
  width: 600px !important;
  height: 550px !important;
  align-self: center !important;
}
