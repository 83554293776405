@import "./varibale";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,500&family=Roboto+Mono:wght@200;300;400;500;600;700&family=Roboto:wght@700;900&family=Tajawal:wght@300;400;500;700;800;900&display=swap");

.mainColor {
  color: $mainColor !important;
}
.secondColor {
  color: $secondColor !important;
}

a {
  text-decoration: none !important;
  color: $colorGrey-0 !important;
}

.btn-primary {
  background-color: $mainColor !important;
  display: flex !important;
  gap: 5px !important;
}

.btn-outline-primary {
  color: $mainColor !important;
  border-color: $mainColor !important;

  &:hover {
    background-color: transparent !important;
  }
}

// table style

thead {
  > tr {
    border-radius: 15px !important;
    white-space: nowrap !important;

    > th {
      font-family: $fontBase;
      font-style: normal;
      font-weight: 700;
      font-size: 18px !important;
      line-height: 27px !important;
      border-bottom: 2px solid #6b6f7b !important;
      text-align: center;
      color: #6b6f7b;
      padding: 15px 8px !important;
    }
  }
}

tbody {
  white-space: nowrap !important;

  .img {
    width: 30%;
  }
}

td {
  white-space: nowrap !important;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: auto;
    gap: 30px;
    font-family: $fontBase;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 32px !important;
    text-align: center;
    color: #000000;

    > svg {
      font-size: 20px;
      cursor: pointer;
    }

    svg:first-child {
      color: $colorGreen;
    }

    svg:last-child {
      color: $mainColor;
    }
  }
}
td {
  white-space: nowrap !important;
}

td {
  > div {
    color: #4e505d !important;
    min-height: 100px !important;
    /* max-width: 160px  !important; */
    // font-size: 1.1rem !important;
    font-weight: 500 !important;
    gap: 1px;
    .ClientPhone {
      font-size: 15px !important;
      font-weight: normal;
      color: #025585;
    }
  }
}

// pagr not found
.app__error {
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.notfound-image {
  @media screen and (max-width: 767px) {
    width: 410px;
  }
}
.notfound-image {
  width: 100px !important;
}
.p-button-outlined {
  border-radius: 50% !important;
}
.p-column-header-content {
  justify-content: center !important;
}
.p-datatable-tbody {
  text-align-last: center !important;
}
.p-checkbox-box {
  border: 3px solid #0075ea !important;
}
.p-checkbox {
  width: 45px !important;
  height: 22px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-paginator-pages {
  display: flex !important;
  gap: 6px !important;
  justify-content: end;
  padding: 15px !important;

  .p-paginator-page {
    width: 38px !important;
    height: 37px !important;
  }
}
.p-toolbar-group-start {
  .p-button {
    display: flex !important;
    gap: 6px !important;
  }
}
.p-button-success {
  background-color: $mainColor !important;
  height: 42px !important;
  // display: flex !important;
  gap: 6px !important;
}
.p-dialog-footer {
  display: flex !important;
  justify-content: center !important;
  gap: 8px !important;
  .p-button {
    background-color: $mainColor !important;
    margin-left: 5px !important;
    border-radius: 5px !important;
    height: 42px !important;
    border: none !important;
    .p-button-icon {
      margin-left: 6px !important;
    }
  }

  .p-button-secondary {
    background-color: #e8eaee !important;
    border-radius: 5px !important;
    border-color: #fff;
    color: $mainColor;
    height: 42px !important;
  }
}
.p-dialog-title {
  text-align: center !important;
}

.p-toolbar {
  background-color: transparent !important;
  border: none !important;
  .p-button-danger {
    background-color: #e8eaee !important;
    color: #d9534f !important    ;
    border-color: $colorGrey-0 !important;
    height: 42px !important;
  }
}
.p-datatable-tbody tr {
  height: 40px !important;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 12px !important;
}
.p-inputtext {
  height: 42px !important;
}
.p-multiselect-label {
  height: 42px !important;
}
.p-checkbox-box {
  border: 2px solid $colorGrey-0 !important;
}
.p-calendar {
  button {
    background-color: transparent !important;
    color: $mainColor;
    height: 42px !important;
    border-right: none !important;
    // border-radius:0px 0px 3px 3px !important;
    border-color: #ced4da !important;
  }
}
.search-btn{
  background-color: $mainColor !important;
  margin-left: 5px !important;
  border-radius: 5px !important;
  height: 42px !important;
  border: none !important;
}
