@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500&display=swap");
// colors
$mainColor: #197455;
$secondColor: #e9bc61;
$textColor: #2d2d2d;
$colorGrey-0: #313A4E;
$colorGrey-1: #999999;
$colorDark: #000000;
$colorLight: #f9f9f9;
$colorLight-0: #ffffff;
$colorLight-1: #f7f9fb;
$defaultColor: #131313;
// Status Colors
$colorGreen: #40ab45;
$colorRed-1: #e20000;
$colorBlue-0: #3182ce;
// font size
$fontSize: 39px;
$fontBase: "Tajawal", sans-serif;
// shadow
$shadow: 0px 6px 7px rgba(188, 188, 188, 0.16);
$shadow-navbar: 0px 0px 3px rgba(0, 0, 0, 0.4);
// border radius
$radius-sm: 5px;
$radius-lg: 10px;

@mixin flex($display, $justify, $align, $dir) {
  display: $display;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $dir;
}

@mixin size($w, $h) {
  width: $w;
  height: $h;
}

/** 
<Col xl={3} lg={4} md={6} sm={12}   className='                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 -danger'>
    <h1>shzloka</h1>
</Col>
 */
