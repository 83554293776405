@import "../style/varibale";
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500&display=swap");

:root {
  --main-color: #1c805f;
  --light-color: #fff;
  --secondary-color: #ffcd6a;
  --default-color: #2D2D2D;

  --shadow: rgba(145, 158, 171, 0.2) 0px 2px 4px -1px, rgba(145, 158, 171, 0.14) 0px 4px 5px 0px,
    rgba(145, 158, 171, 0.12) 0px 1px 10px 0px;
  --font-family: "Tajawal", sans-serif;
}

body {
  background: #f8f8f8 !important ;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.secondary-bg {
  background-color: var(--secondary-color);
}
.text-main {
  color: var(--main-color);
}
.default-text {
  color: var(--default-color);
}
.text-secondary {
  color: #ffcd6a !important;
}
.main-bg {
  background-color: #1c805f !important;
}

.modal {
  z-index: 999999997 !important;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $mainColor;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: $mainColor, $alpha: 0.8);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

// button {
//   border-color: $mainColor !important;
// }

td {
  vertical-align: middle !important;
}

.ClientPhone {
  direction: ltr !important;
}
.pages-container {
  background-color: #f8f8f8 !important  ;
}
.p-component {
  font-family: "Tajawal", sans-serif !important;
}
.report-head {
  font-size: 26px;
}
.p-tabview-ink-bar{
  display: none !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
  border-color: $mainColor ;
  border-width: 0 0 2px 0 !important ; 
} 
.dashboard-box {
  background: #fff;
  height: 120px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .pi{
    color: $secondColor;
  }
  .h5{
    color:#949494 !important;
  }
}
.card-shadow{
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.04) !important ;
}