
@import "../../style/varibale";

.active > .page-link,
.page-link.active {
  background-color: $mainColor !important;
  border-color: $mainColor !important;
}
.pagination {
    display: flex !important;
    gap: 6px !important;
    justify-content: end;
    padding: 15px !important;
    
    .page-item {
      width: 38px !important;
      height: 37px !important;
    }
    
    .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $mainColor !important;
      border-radius: 6px !important;
    }
  
  
  .active .page-link{
    color: #fff !important;

  }
} 
